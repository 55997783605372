//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
    CWinTmp: () => import("@/components/CWinTmp"),
  },
  props: {
    isAdd: {
      type: Boolean,
      default: null,
    },
    selRow: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      indexData: {
        type: "Add",
        name: "添加角色",
        xModalName: "roleModal",
      },
      formData: { role: null },
      Rules: {
        role: [{ required: true, message: "输入角色名称", trigger: "blur" }],
      },
    };
  },
  methods: {
    /**
     * 弹窗打开回调
     */
    opened() {
      this.$nextTick(() => {
        if (this.isAdd) {
          this.indexData.name = "添加角色";
        } else {
          this.indexData.name = "编辑角色";
          this.formData.role = this.selRow.Name;
        }
      });
    },
    /**
     * 弹窗关闭回调
     */
    closed() {
      this.formData.role = null;
    },
    /**
     * 提交
     */
    topSubmit() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          this.$refs.cwt.toggleSubmit();
          if (this.isAdd) {
            this.addRole();
          } else {
            this.editRole();
          }
        } else {
          return;
        }
      });
    },
    /**
     * 确认添加
     */
    addRole() {
      this.$http
        .post("/Management/RoleManagement/AddRole.ashx", {
          name: this.formData.role,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              message: resp.msg,
              type: "success",
            });
            this.$modal.hide("roleModal");
            this.$emit("success");
          }
        });
    },
    /**
     * 确认编辑
     */
    editRole() {
      this.$http
        .post("/Management/RoleManagement/EditRole.ashx", {
          name: this.formData.role,
          id: this.selRow.Id,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              message: resp.msg,
              type: "success",
            });
            this.$modal.hide("roleModal");
            this.$emit("success");
          }
        });
    },
  },
};
